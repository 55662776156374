<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/a_list' }">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动报名情况</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 查询 -->
    <el-card>
      <div class="title">查询</div>
      <div class="item">
        部门：
        <el-select
          v-model="department"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in depart_list"
            :key="item.departName"
            :label="item.departName"
            :value="item.departName"
          >
          </el-option>
        </el-select>
      </div>

      <div class="item">
        工号：
        <el-input
          v-model="jobnumber"
          placeholder="请输入内容"
        ></el-input>
      </div>

      <div class="item">
        用户姓名：
        <el-input
          v-model="name"
          placeholder="请输入内容"
        ></el-input>
      </div>

      <el-button
        round
        type=primary
        @click="search"
      >搜索</el-button>
    </el-card>

    <!-- 内容区域 -->
    <el-card>
      <div class="activity_name">{{activity_name}} ———— 活动报名情况</div>
      <el-button
        round
        type="warning"
        @click="download"
        style="float:right"
      >导出报表</el-button>
      <el-table
        :data="userlist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="jobNumber"
          label="工号"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
        >

        </el-table-column>
        <el-table-column
          prop="nickname"
          label="微信昵称"
        >
        </el-table-column>
        <el-table-column
          prop="department"
          label="部门"
        >
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
        >
        </el-table-column>
        <el-table-column
          prop="telNumber"
          label="联系电话"
        >
        </el-table-column>
        <el-table-column
          prop="signupTime"
          label="报名时间"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "A_enroll",
  data() {
    return {
      depart_list: [],
      activeName: 0,
      department: null,
      jobnumber: null,
      name: null,
      activity_name: "",
      userlist: [],
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 获取活动报名情况列表
    async get_activitylist() {
      showLoading();
      // 接收活动id
      var activity_id = this.$route.query.activity_id;
      // 接收活动名字
      this.activity_name = this.$route.query.activity_name;

      // 请求该活动报名情况
      const { data: res } = await this.$http.get(
        `/activity/signupUserList/${activity_id}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          params: {
            pageNum: this.currentpage,
            pageSize: 15,
            jobNumber: this.jobnumber,
            department: this.department,
            name: this.name
          }
        }
      );
      this.userlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
      // console.log(this.userlist)
      if (res) {
        hideLoading();
      }
      this.name = null;
      this.department = null;
      this.jobnumber = null;
    },

    // 查询
    search() {
      this.currentpage = 1;
      this.get_activitylist();
    },

    // 获取部门列表
    async getdepartlist() {
      const { data: res } = await this.$http.get(`/departs/list/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },
        params: {
          pageNum: 1,
          pageSize: 100
        }
      });
      // console.log(res);
      this.depart_list = res.entity.list;
    },

    // 导出表格
    download() {
      const obj = `https://api.healthybnuz.cn/activity/exportListSignupUserInfoDTO/${
        this.$route.query.activity_id
      }/${
        this.$route.query.activity_name
      }?Token=${window.sessionStorage.getItem("token")}`;
      window.location.href = obj;
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.get_activitylist();
    }
  },
  created() {
    this.get_activitylist();
    this.getdepartlist();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.el-input {
  width: 250px;
}
.item {
  display: inline;
  white-space: nowrap;
  padding-right: 150px;
}
.activity_name {
  font-weight: bold;
  padding-bottom: 15px;
}
</style>
