<template>
  <el-container class="home_container">
    <!-- 头部区域 -->
    <el-header>
      <!-- 文字 -->
      <div class="bnuz">运动健康管理系统</div>
      <!-- 右侧admin部分 -->
      <div class="user">
        <i class="el-icon-user-solid"></i>
        <span class="admin">admin</span>
        <el-dropdown class="admin_menu">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>设置</el-dropdown-item>
            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!-- 页面 -->
    <el-container>
      <el-aside>
        <el-menu
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          :unique-opened="true"
          :router="true"
          :default-active="$route.path"
        >
          <el-submenu
            v-for="item in menulist"
            :key="item.index"
            :index="item.id + ''"
          >
            <template slot="title">
              <i :class="item.icon"></i>
              <span
                slot="title"
                class="menu_title"
              >
                {{item.name}}
              </span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="kid in item.childrenmenulist"
                :key="kid.id"
                :index="kid.path"
              >{{kid.name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isCollapse: false,
      menulist: [
        {
          id: 0,
          name: "活动管理",
          icon: "el-icon-s-flag",
          childrenmenulist: [
            {
              id: "0-1",
              name: "活动列表",
              path: "/a_list"
            },
            {
              id: "0-2",
              name: "添加活动",
              path: "/a_add"
            }
          ]
        },
        {
          id: 1,
          name: "部门管理",
          icon: "el-icon-menu",
          childrenmenulist: [
            {
              id: "1-1",
              name: "部门列表",
              path: "/d_list"
            },
            {
              id: "1-2",
              name: "添加部门",
              path: "/d_add"
            }
          ]
        },
        {
          id: 2,
          name: "用户管理",
          icon: "el-icon-user-solid",
          childrenmenulist: [
            {
              id: "2-1",
              name: "用户列表",
              path: "/u_list"
            },
            {
              id: "2-2",
              name: "添加用户",
              path: "/u_add"
            },
            {
              id: "2-3",
              name: "批量导入",
              path: "/u_add_plus"
            }
          ]
        },
        {
          id: 3,
          name: "报表管理",
          icon: "el-icon-s-data",
          childrenmenulist: [
            {
              id: "3-1",
              name: "勋章获得记录",
              path: "/r_medal"
            },
            {
              id: "3-2",
              name: "日常步数记录",
              path: "/r_step"
            },
            {
              id: "3-3",
              name: "健康档案记录",
              path: "/r_health"
            },
            {
              id: "3-4",
              name: "部门记录",
              path: "r_depart"
            }
          ]
        },
        {
          id: 4,
          name: "知识管理",
          icon: "el-icon-question",
          childrenmenulist: [
            {
              id: "4-1",
              name: "知识列表",
              path: "k_list"
            },
            {
              id: "4-2",
              name: "添加知识",
              path: "/k_add"
            },
            {
              id: "4-3",
              name: "虚拟步数设置",
              path: "/k_virtualstep_set"
            },
            {
              id: "4-4",
              name: "批量导入",
              path: "k_add_plus"
            },
            {
              id: "4-5",
              name: "虚拟步数获取记录",
              path: "/k_virtualsteps_record"
            }
          ]
        },
        
        {
          id: 5,
          name: "微信模块管理",
          icon: "el-icon-s-tools",
          childrenmenulist: [
            {
              id: "5-1",
              name: "微信模块",
              path: "/w_management"
            }
          ]
        },
        {
          id: 6,
          name: "新闻管理",
          icon: "el-icon-reading",
          childrenmenulist: [
            {
              id: "6-1",
              name: "新闻列表",
              path: "/n_list"
            },
            {
              id: "6-2",
              name: "添加新闻",
              path: "/n_add"
            }
          ]
        },
        {
          id: 7,
          name: "异常监控",
          icon: "el-icon-view",
          childrenmenulist: [
            {
              id: "7-1",
              name: "监控列表",
              path: "/m_list"
            }
          ]
        },
        {
          id: 8,
          name: "公众号管理",
          icon: "el-icon-news",
          childrenmenulist: [
            {
              id: "8-1",
              name: "新闻列表",
              path: "/o_list"
            }
          ]
        },
      ]
    };
  },
  methods: {
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.replace("/login");
      return this.$message.success("退出成功");
    }
  }
};
</script>

<style lang="less">
.home_container {
  height: 100%;
}
.el-header {
   background-color: #2693FE;
  height: 40px !important;
  position: relative;
  padding: 0 !important;
}
.el-aside {
  width: 300px !important;
  background: white;
}
.el-main {
  background-color: #eaedf1;
}
.bnuz {
  font-weight: bolder;
  color: #FFFFFF;
  height:40px;
  background-color: #2693FE;
  padding-left: 50px;
  line-height: 40px;
  font-size:18px;
}
.user {
  float: right;
  position: absolute;
  right: 20px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
}
.user .el-icon-user-solid {
  font-size: 18px;
}
.el-icon-arrow-down {
  color: white;
}
.user .admin {
  padding-left: 10px;
}
.user .el-icon--right {
  color: black;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
}
.el-menu {
  border-right: none !important;
}
.el-radio-button__inner {
  background: none !important;
  border: none !important;
  color: black !important;
  font-size: 22px !important;
}
.el-menu-item,
.el-submenu__title {
  width: 300px !important;
}
</style>
