<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>健康档案记录</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 表格部分 -->
    <el-card>
      <el-button
        round
        type="warning"
        @click="download"
      >导出表格</el-button>
      <el-table
        :data="healthlist"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="jobNumber"
          label="账号"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="height"
          label="身高"
        >
        </el-table-column>
        <el-table-column
          prop="weight"
          label="体重"
        >
        </el-table-column>
        <el-table-column
          prop="ep"
          label="收缩压"
        >
        </el-table-column>
        <el-table-column
          prop="dp"
          label="舒张压"
        >
        </el-table-column>
        <el-table-column
          prop="bmi"
          label="BMI"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "R_health",
  data() {
    return {
      date: "",
      healthlist: [],
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 获取健康档案列表
    async get_healthlsit() {
      showLoading();
      const { data: res } = await this.$http.get(`/userinfo/userHealthList/`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          token: window.sessionStorage.getItem("token")
        },

        params: {
          pageNum: this.currentpage,

          pageSize: 12
        }
      });
      this.healthlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
      console.log(res);
      if (res) {
        hideLoading();
      }
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      this.get_healthlsit();
    },

    // 导出健康档案记录表格
    download() {
      const obj = `https://api.healthybnuz.cn/userinfo/userHealthAddUp/?Token=${window.sessionStorage.getItem('token')}`;
      window.location.href = obj;
    }
  },
  created() {
    this.get_healthlsit();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.el-button--primary {
  margin-left: 50px;
}
.el-button--warning {
  float: right;
}
</style>
