import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './plugins/element.js'
import './plugins/axios'
import './assets/css/global.css'
import qs from 'qs'
Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.HOST = '/api'
Vue.prototype.$qs = qs
// 配置请求的根路径
axios.defaults.baseURL = 'https://api.healthybnuz.cn'
// // 拦截器
// axios.interceptors.request.use(config => {
//   // console.log(config)
//   // return config
//   config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
//   if(config.method === 'post') { // post请求时，处理数据
//       config.data = qs.stringify( {
//           ...config.data //后台数据接收这块需要以表单形式提交数据，而axios中post默认的提交是json数据,所以这里选用qs模块来处理数据，也有其他处理方式，但个人觉得这个方式最简单好用
//       })
//   } 
//   return config;
// })
// //添加响应拦截器
// axios.interceptors.response.use(function(response){
//   return response;
// },function(error){
//  return Promise.reject(error);
// });

axios.interceptors.request.use(config => {
  return config
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
