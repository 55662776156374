<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <!-- 刷新 -->
      <el-button
        type="warning"
        round
        @click="refresh"
      >刷新</el-button>
      <!-- 表格 -->
      <el-table
        :data="offiaccout_list"
        stripe
        style="width: 100%"
      >
       <el-table-column
          prop="publishedTime"
          label="发布时间"
        >
        </el-table-column>
        <el-table-column
          prop="title"
          label="新闻标题"
        >
        </el-table-column>
        
        <el-table-column
          prop="url"
          label="新闻链接"
        >
        </el-table-column>
        <el-table-column label="操作">
        
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="edit(scope.$index)"
            ></el-button>

            <el-button
              type="success"
              icon="el-icon-news"
              circle
              @click="goto(scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 修改部门信息弹框 -->
    <el-dialog
      title="修改新闻发布时间"
      :visible.sync="dialogFormVisible"
    >
      <el-form>
        <el-form-item
          label="活动主题："
          label-width="120px"
        >{{this.title}}
        </el-form-item>
        <el-form-item
          label="修改时间："
          label-width="120px"
        >
          <el-date-picker
          v-model="modify_time"
          type="datetime"
          placeholder="选择日期时间"
          
          value-format="timestamp">
        </el-date-picker>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {showLoading, hideLoading} from '@/plugins/element.js'
export default {
  name: "O_list",
  data() {
    return {
      offiaccout_list: [],
      dialogFormVisible: false,
      modify_time:'',
      artid:'',
      title:'',
      id: Number
    };
  },
  created() {
    this.getoffiaccoutlist();
  },
  methods: {
    // 获取新闻数据
    async getoffiaccoutlist() {
      showLoading()
      const { data: res } = await this.$http.get(`/offiaccout/getAllNews`, {
        headers: {
          Accept: "application/json;charset=UTF-8",
          Token: window.sessionStorage.getItem("token")
        },    
      });
      this.offiaccout_list = res.entity;
      console.log(this.offiaccout_list);
     
      if(res) {
        hideLoading();
      }
      console.log(this.offiaccout_list);
    },

    // 修改时间
    edit(index) {
      this.dialogFormVisible = true;
      this.artid = this.offiaccout_list[index].articleId;
      this.title = this.offiaccout_list[index].title;
    },

    // 修改时间并上传
    async submit() {
      // 上传修改信息
      const { data: res } = await this.$http.post(
        `/offiaccout/updatePublishedTime/${this.artid}/${this.modify_time/1000}?Token=${window.sessionStorage.getItem("token")}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
          }
        }
      );
      console.log(res);
      if(res.code === 200) {
        this.$message.success('修改成功')
        this.dialogFormVisible = false;
        this.getoffiaccoutlist();
      } else {
        this.$message.error('修改失败')
      }
    },
    //跳转链接
    goto(index){
      window.location.href = this.offiaccout_list[index].url;
    },
    
    //获取最新公众号新闻列表
    async refresh() {
     
    const { data: res } = await this.$http.post(
        `/offiaccout/saveLatestNews?Token=${window.sessionStorage.getItem("token")}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
          }
        }
      );
      console.log(res);
      if(res.code === 200) {
        this.$message.success('刷新成功')
        this.dialogFormVisible = false;
        this.getoffiaccoutlist();
      } else {
        this.$message.error('刷新失败')
      }
    }
    
    // 删除部门
    // async del(index) {
    //   const id = this.offiaccout_list[index].id;
    //   const { data: res } = await this.$http.delete(`/departs/${id}`, {
    //     headers: {
    //       Accept: "application/json;charset=UTF-8",
    //       Token: window.sessionStorage.getItem("token")
    //     }
    //   });
    //   if (res.code === 200) {
    //     this.$message.success("删除成功");
    //     this.getdepartmentlist();
    //   } else {
    //     this.$message.error("删除失败");
    //   }
    // },

  }
};
</script>

<style lang="less" scoped>
.el-button--warning {
  float: right;
  display: block;
}
</style>
