<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/a_list' }">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动步数排行榜</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容区域 -->
    <el-card>
      <div class="activity_name">{{activity_name}} ———— 活动步数排行榜</div>
      <!-- 选项卡 -->
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="个人成绩"
          name=0
        ></el-tab-pane>
        <el-tab-pane
          label="部门成绩"
          name=1
        ></el-tab-pane>
      </el-tabs>
      <!-- 导出成绩 -->
      <el-button
        type="warning"
        round
        @click="download"
      >导出{{type}}成绩</el-button>

      <!-- 个人成绩表格 -->
      <el-table
        :data="userlist"
        stripe
        style="width: 100%"
        v-show="show"
      >
        <el-table-column
          prop="name"
          label="姓名"
        >
        </el-table-column>
        <el-table-column
          prop="department"
          label="部门"
        >

        </el-table-column>
        <el-table-column
          prop="step"
          label="用户步数"
        >
        </el-table-column>
        <el-table-column
          prop="finishedPointNum"
          label="关卡"
        >
        </el-table-column>
      </el-table>

      <!-- 部门成绩表格 -->
      <el-table
        :data="departmentlist"
        stripe
        style="width: 100%"
        v-show="!show"
      >
        <el-table-column
          prop="department"
          label="部门"
        >
        </el-table-column>
        <el-table-column
          prop="signupNum"
          label="报名人数"
        >

        </el-table-column>
        <el-table-column
          prop="totalStep"
          label="总步数"
        >
        </el-table-column>
        <el-table-column
          prop="averageStep"
          label="平均步数"
        >
        </el-table-column>
        <el-table-column
          prop="finishedUserNum"
          label="完成人数"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentpage"
        :page-count="pagecount"
        class="pagination"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { showLoading, hideLoading } from "@/plugins/element.js";
export default {
  name: "A_rank",
  data() {
    return {
      activeName: 0,
      userlist: [],
      departmentlist: [],
      type: "个人",
      activity_name: "",
      condition: 1,
      activity_id: "",
      show: true,
      currentpage: 1,
      total: 0,
      pagecount: 0
    };
  },
  methods: {
    // 选项卡切换个人成绩 / 部门成绩
    handleClick() {
      this.show = !this.show;
      console.log(this.activeName);
      if (this.activeName == 0) {
        this.getuserdata();
      } else if (this.activeName == 1) {
        this.getdeparmentdata();
        this.type = "部门";
      }
    },

    // 获取个人排行榜数据
    async getuserdata() {
      showLoading();
      const { data: res } = await this.$http.get(
        `/activity/rankUserInfoList/${this.activity_id}/${this.condition}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          params: {
            pageNum: this.currentpage,
            pageSize: 15,
            sortDirection: "DESC"
          }
        }
      );
      if (res) {
        hideLoading();
      }
      this.userlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
    },

    // 获取部门排行榜数据
    async getdeparmentdata() {
      showLoading();
      const { data: res } = await this.$http.get(
        `/activity/rankDepartDTOList/${this.activity_id}/${this.condition}`,
        {
          headers: {
            Accept: "application/json;charset=UTF-8",
            Token: window.sessionStorage.getItem("token")
          },
          params: {
            pageNum: this.currentpage,
            pageSize: 15,
            sortDirection: "DESC"
          }
        }
      );
      if (res) {
        hideLoading();
      }
      this.departmentlist = res.entity.list;
      this.total = res.entity.total;
      this.pagecount = res.entity.pages;
    },

    // 导出报表
    download() {
      // 个人
      if (this.activeName === "0") {
        // const obj = `https://api.healthybnuz.cn/activity/exportListRankUserInfoDTO/${
        //   this.$route.query.activity_id
        // }/${
        //   this.$route.query.activity_name
        // }?Token=${window.sessionStorage.getItem("token")}`;
        // window.location.href = obj;

        //新的个人接口
        const obj = `https://api.healthybnuz.cn/excel/activityStepRank/?aid=${
          this.$route.query.activity_id
        }&&Token=${window.sessionStorage.getItem("token")}`;
       window.location.href = obj;
      }
      
      // 部门
      else if (this.activeName === "1") {
        const obj = `https://api.healthybnuz.cn/activity/exportListRankDepartDTO/${
          this.$route.query.activity_id
        }/${
          this.$route.query.activity_name
        }?Token=${window.sessionStorage.getItem("token")}`;
        window.location.href = obj;
      }
    },

    // 切换页数
    handleCurrentChange(currentpage) {
      this.currentpage = currentpage;
      if(this.activeName === "0") {
        this.getuserdata();
      } else if (this.activeName === "1") {
        this.getdeparmentdata();
      }
    },
  },
  created() {
    // 接收活动id
    this.activity_id = this.$route.query.activity_id;
    // 接收活动名字
    this.activity_name = this.$route.query.activity_name;

    this.getuserdata();
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 30px;
}
.activity_name {
  font-weight: bold;
  padding-bottom: 20px;
}
.el-button--warning {
  float: right;
}
</style>
